<script setup lang="ts">
import { SEGMENT_TRACK_EVENTS } from "~/constants";

export interface BaseHeaderVisibleLinksProps {
  /**
   * An array of header links to be displayed.
   */
  links: IHeaderLink[];
}

const { t } = useI18n();
const route = useRoute();
const segment = useSegment();
const runtimeConfig = useRuntimeConfig().public;
const props = defineProps<BaseHeaderVisibleLinksProps>();
const activeLink = ref<null | IHeaderLink>(null);
const isEnabledTeams = runtimeConfig.APP_CUSTOMER.enable_teams;
const isLeague = runtimeConfig.APP_CUSTOMER.enable_leagues_view;
const onHeaderLinkHover = async (link: IHeaderLink) => (activeLink.value = link);
const onHeaderLinkLeave = () => (activeLink.value = null);

function onHeaderLinkClick(link: IHeaderLink) {
  if (link.id === "archive")
    return;

  if (isLeague) {
    segment.track(SEGMENT_TRACK_EVENTS.CLICK_ON_LEAGUE, {
      league_id: link.id,
      league_name: link.title,
    });
  }
}

watch(route, onHeaderLinkLeave);
</script>

<template>
  <div v-if="props.links.length" class="baseHeader__links">
    <BaseButton
      v-for="link in props.links"
      :key="link.id"
      class="baseHeader__link"
      link
      type="secondary"
      :tag="link.href.includes('http') ? 'a' : 'nuxt-link'"
      :to="link.href.includes('http') ? {} : link.href"
      :href="link.href.includes('http') ? link.href : undefined"
      :name="link.title"
      :title="link.title"
      @mouseenter="onHeaderLinkHover(link)"
      @click.capture="onHeaderLinkClick(link)"
    >
      {{ link.id === 'following' || link.id === 'archive' ? t(`labels.${link.id}`) : link.title }}
    </BaseButton>

    <div v-if="isEnabledTeams && activeLink && activeLink.subLinks?.length" class="baseHeaderPopup" @mouseleave="onHeaderLinkLeave">
      <div class="px-[7.5rem] flex flex-col">
        <div class="subLinksGrid">
          <BaseLink
            v-for="link in activeLink.subLinks"
            :key="link.id"
            :title="link.title"
            :image-url="link.imageUrl"
            :href="link.href"
          />
        </div>
      </div>
      <div v-if="activeLink.hasMore" class="mt-8 flex justify-center">
        <BaseButton name="category" link tag="nuxt-link" :to="activeLink.href">
          {{ t('labels.see_all') }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.baseHeaderPopup {
  @apply absolute top-24 left-0 w-full py-8 transition z-[31];
  background: var(--base-card-bgColor);
  box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.18);
  height: auto;
}

.subLinksGrid {
  @apply grid items-center grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8 3xl:grid-cols-10;
}
</style>
